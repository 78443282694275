import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  AppBar,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

function Alarms() {
  const [data, setData] = useState([]);
  const [newAlarm, setNewAlarm] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alertMethods, setAlertMethods] = useState(["SMS", "Email"]);
  const rowsWithIds = Array.isArray(data)
    ? data.map((row) => ({ ...row, id: row.node_id }))
    : [];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get("http://localhost:5051/get_alarm_conf")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const columns = [
    { field: "node_id", headerName: "node_id", flex: 1 },
    { field: "colname", headerName: "colname", flex: 1 },
    { field: "min", headerName: "min", flex: 1 },
    { field: "max", headerName: "max", flex: 1 },
    { field: "alert_method", headerName: "alert_method", flex: 1 },
    { field: "severity", headerName: "severity", flex: 1 },
  ];

  const defaultSortingOrder = [{ field: "node_id", sort: "asc" }];

  const getRowId = (row) => row.node_id;

  const handleRowSelection = (params) => {
    setSelectedRows(params.selectionModel);
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length === 0) {
      alert("Please select row(s) to delete.");
      return;
    }

    const nodeIdsToDelete = selectedRows.map(
      (selectedRow) => selectedRow.node_id
    );

    axios
      .delete("http://localhost:5051/get_alarm_conf", {
        data: { node_ids: nodeIdsToDelete }, // Use a different key like node_ids
      })
      .then((response) => {
        if (response.status === 200) {
          // Deletion was successfulconst rowsWithIds = Array.isArray(data) ? data.map((row) => ({ ...row, id: row.node_id })) : [];

          fetchData(); // Fetch updated data
          setSelectedRows([]); // Clear selected rows
          console.log("Deleted successfully");
        } else {
          console.error("Error deleting alarms. Status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error deleting alarms:", error);
      });
  };

  const handleAddAlarm = () => {
    if (
      !newAlarm.node_id ||
      !newAlarm.colname ||
      !newAlarm.min ||
      !newAlarm.max ||
      !newAlarm.alert_method ||
      !newAlarm.severity
    ) {
      alert("Please fill in all fields.");
      return;
    }

    axios
      .post("http://localhost:5051/get_alarm_conf", newAlarm)
      .then(() => {
        setNewAlarm({}); // Reset the form after adding the alarm
        setOpen(false); // Close the dialog
        fetchData(); // Fetch data again after add to update the DataGrid
      })
      .catch((error) => {
        console.error("Error adding alarm:", error);
      });
  };

  const handleCloseDialog = () => {
    setOpen(false); // Close the dialog when the "Cancel" button is clicked
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        style={{ backgroundColor: "white", marginBottom: "20px" }}
      >
        <Toolbar>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            + Add Alarm
          </Button>
          <div style={{ width: "20px" }} /> {/* Add space between buttons */}
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteSelected}
          >
            Delete Alarm
          </Button>
        </Toolbar>
      </AppBar>
      <div
        style={{
          height: "calc(100vh - 60px)",
          width: "100%",
          position: "relative",
        }}
      >
        {/* Display the alarms list */}
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={rowsWithIds}
            getRowId={getRowId}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            selectionModel={selectedRows}
            onSelectionModelChange={handleRowSelection}
            SelectionOnClick
            checkboxSelection={true}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            disableColumnReorder
            disableMultipleColumnsSorting
            sortingOrder={defaultSortingOrder}
            loading={data.length === 0}
          />
        </div>
        {/* Add Alarm Dialog */}
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Add Alarm</DialogTitle>
          <DialogContent>
            <form>
              <TextField
                label="Node ID"
                value={newAlarm.node_id || ""}
                onChange={(e) =>
                  setNewAlarm({ ...newAlarm, node_id: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Column Name"
                value={newAlarm.colname || ""}
                onChange={(e) =>
                  setNewAlarm({ ...newAlarm, colname: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Min Value"
                value={newAlarm.min || ""}
                onChange={(e) =>
                  setNewAlarm({ ...newAlarm, min: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Max Value"
                value={newAlarm.max || ""}
                onChange={(e) =>
                  setNewAlarm({ ...newAlarm, max: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Alert Method</InputLabel>
                <Select
                  value={newAlarm.alert_method || ""}
                  onChange={(e) =>
                    setNewAlarm({ ...newAlarm, alert_method: e.target.value })
                  }
                >
                  {alertMethods.map((method) => (
                    <MenuItem key={method} value={method}>
                      {method}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Severity"
                value={newAlarm.severity || ""}
                onChange={(e) =>
                  setNewAlarm({ ...newAlarm, severity: e.target.value })
                }
                fullWidth
                margin="normal"
              />
            </form>
          </DialogContent>
          <Button
            onClick={handleAddAlarm}
            variant="contained"
            color="primary"
            size="small"
          >
            Add
          </Button>
          <Button onClick={handleCloseDialog} size="small">
            Cancel
          </Button>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}

export default Alarms;
