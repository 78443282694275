import React, { useState, useEffect } from "react";
import "../css/Dashboard.css";
import axios from "axios";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function Dashboard() {
  const [data, setData] = useState([]);
  const [numberOfDevices, setNumberOfDevices] = useState(null);
  const [alertMethods, setAlertMethods] = useState(["SMS", "Email"]);

  useEffect(() => {
    // Define the API URL
    const apiUrl = "http://localhost:5051/get_node_overview";

    // Fetch data using Axios
    axios
      .get(apiUrl)
      .then((response) => {
        // Assuming the response contains an array of devices
        const devices = response.data;
        const numDevices = devices.length;
        setNumberOfDevices(numDevices);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get("http://localhost:5051/get_alarm_conf")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const [newAlarm, setNewAlarm] = useState({});
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false); // Close the dialog when the "Cancel" button is clicked
  };

  const handleAddAlarm = () => {
    if (
      !newAlarm.node_id ||
      !newAlarm.colname ||
      !newAlarm.min ||
      !newAlarm.max ||
      !newAlarm.alert_method ||
      !newAlarm.severity
    ) {
      alert("Please fill in all fields.");
      return;
    }

    axios
      .post("http://localhost:5051/get_alarm_conf", newAlarm)
      .then(() => {
        setNewAlarm({}); // Reset the form after adding the alarm
        setOpen(false); // Close the dialog
        fetchData(); // Fetch data again after add to update the DataGrid
      })
      .catch((error) => {
        console.error("Error adding alarm:", error);
      });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="4" sm="7">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Devices</p>
                      <Card.Title as="h5">
                        Number of devices: {numberOfDevices}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="4" sm="7">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Alarms</p>
                      <Card.Title as="h5">
                        Number of alarms: {numberOfDevices}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                  >
                    + Add Alarm
                  </Button>
                  <Dialog open={open} onClose={handleCloseDialog}>
                    <DialogTitle>Add Alarm</DialogTitle>
                    <DialogContent>
                      <form>
                        <TextField
                          label="Node ID"
                          value={newAlarm.node_id || ""}
                          onChange={(e) =>
                            setNewAlarm({
                              ...newAlarm,
                              node_id: e.target.value,
                            })
                          }
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Column Name"
                          value={newAlarm.colname || ""}
                          onChange={(e) =>
                            setNewAlarm({
                              ...newAlarm,
                              colname: e.target.value,
                            })
                          }
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Min Value"
                          value={newAlarm.min || ""}
                          onChange={(e) =>
                            setNewAlarm({ ...newAlarm, min: e.target.value })
                          }
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Max Value"
                          value={newAlarm.max || ""}
                          onChange={(e) =>
                            setNewAlarm({ ...newAlarm, max: e.target.value })
                          }
                          fullWidth
                          margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Alert Method</InputLabel>
                          <Select
                            value={newAlarm.alert_method || ""}
                            onChange={(e) =>
                              setNewAlarm({
                                ...newAlarm,
                                alert_method: e.target.value,
                              })
                            }
                          >
                            {alertMethods.map((method) => (
                              <MenuItem key={method} value={method}>
                                {method}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          label="Severity"
                          value={newAlarm.severity || ""}
                          onChange={(e) =>
                            setNewAlarm({
                              ...newAlarm,
                              severity: e.target.value,
                            })
                          }
                          fullWidth
                          margin="normal"
                        />
                      </form>
                    </DialogContent>
                    <Button
                      onClick={handleAddAlarm}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Add
                    </Button>
                    <Button onClick={handleCloseDialog} size="small">
                      Cancel
                    </Button>
                  </Dialog>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="4" sm="7">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Errors</p>
                      <Card.Title as="h4">23</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o mr-1"></i>
                  In the last hour
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Recent Alarms</Card.Title>
                <p className="card-category">Order by date</p>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                ></div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock"></i>
                  Updated
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
