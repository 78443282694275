import React from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";
import { Nav, Navbar, Button } from "react-bootstrap";
import Dashboard from "./Dashboard";
import Alarms from "./Alarms";
import DeviceAlarms from "./DeviceAlarms";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import DeviceLayoutTable from "./DeviceLayoutTable";
import DeviceDashboard from "./DeviceDashboard";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// assigns id, aria controls to each element
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DeviceLayout() {
  const { deviceId } = useParams(); // Get the deviceId from the URL parameter
  console.log("deviceId:", deviceId);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Function to change the rate for the device
  const handleChangeRate = (rate) => {
    // Send a request to the backend to update the rate for the device
    fetch(`get_node_overview/${deviceId}/rate`, {
      // update url
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ rate }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data.message)) // Log the response from the server
      .catch((error) => console.error("Error:", error));
  };

  // Function to restart the device
  const handleRestartDevice = () => {
    // Send a request to the backend to restart the device
    fetch(`get_node_overview/devices/${deviceId}/restart`, {
      // Update url
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => console.log(data.message)) // Log the response from the server
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div>
      <Typography variant="h3" component="h2">
        Device {deviceId}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Dashboard" {...a11yProps(0)} />
          <Tab label="Info" {...a11yProps(1)} />
          <Tab label="Alarm Log" {...a11yProps(2)} />
          <Tab label="Telemetry" {...a11yProps(3)} />
          <Tab label="Config" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {/* edit here */}
        Specify timeframe and it displays all possible graphs for the specific
        sensor. (ie just line charts) Default timeframe is from the start of
        collected data.
        <DeviceDashboard deviceId={deviceId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {/* edit here */}
        <DeviceLayoutTable deviceId={deviceId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <DeviceAlarms deviceId={deviceId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        Live data feed
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        Device Configuration ie buttons to control device
        <div>
          <Button variant="primary" onClick={() => handleChangeRate(2)}>
            Change Rate to 2 seconds
          </Button>
          <Button variant="danger" onClick={handleRestartDevice}>
            Restart Device
          </Button>
        </div>
      </CustomTabPanel>
    </div>
  );
}

export default DeviceLayout;
