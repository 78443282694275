import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import BuildIcon from "@mui/icons-material/Build";

function DeviceAlarms({ deviceId }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchDeviceAlarms();
  }, [deviceId]);

  const fetchDeviceAlarms = () => {
    //
    axios
      .get(`http://localhost:5051/devices/${deviceId}/alarms_log`)
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error("Error fetching device alarms:", error);
      });
  };

  const getRowId = (row) => row.node_id;

  const columns = [
    { field: "node_id", headerName: "node_id", flex: 1 },
    { field: "colname", headerName: "colname", flex: 1 },
    { field: "min", headerName: "min", flex: 1 },
    { field: "max", headerName: "max", flex: 1 },
    { field: "alert_method", headerName: "alert_method", flex: 1 },
    { field: "severity", headerName: "severity", flex: 1 },
  ];

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <div className="d-flex justify-content-between pb-2">
        <h2>Alarms Log</h2>
        <Button variant="info text-white">
          Configure Alarms for Device {deviceId}
          <BuildIcon fontSize="small" className="mx-2" />
        </Button>
      </div>
      <DataGrid
        columns={columns}
        rows={data}
        getRowId={getRowId}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
        checkboxSelection={false}
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter
        disableColumnReorder
        disableMultipleColumnsSorting
      />
    </div>
  );
}

export default DeviceAlarms;
