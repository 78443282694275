import React from "react";
import { Route, Routes } from "react-router-dom";

import Sidebar from "./Sidebar";
import routes from "../routes.js";
import Dashboard from "./Dashboard";
import "../css/Admin.css";

function Admin() {
  const [color] = React.useState("black");

  return (
    <div className="wrapper">
      <Sidebar color={color} routes={routes} />
      <div className="main-panel">
        <div className="content">
          <Routes>
            {routes.map((prop, key) => (
              <Route
                path={prop.layout + prop.path}
                element={prop.component}
                key={key}
              />
            ))}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Admin;
