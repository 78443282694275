// component that manages the device specific dashboard

import axios from "axios";
import { useState, useEffect } from "react";
import Graph from "./Graph.js";
import { GraphTest, GraphTest2 } from "./GraphTest.js";

// temporary considered values:
const tempSensorVals = [
  "TempHighF",
  "HumidityAvgPercent",
  "VisibilityHighMiles",
  "WindAvgMPH",
];

function DeviceDashboard({ deviceId }) {
  // function to get sensors associated to device
  // by default we collect all the data
  const currentDate = new Date();
  const timestamp = currentDate.getTime();

  const [sensors, setSensors] = useState(null); // list of sensors
  const [data, setData] = useState(null); // data
  const [metainfo, setMetainfo] = useState(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(timestamp);

  // items in square bracked indicate which prop change will trigger the useeffect
  useEffect(() => {
    fetchSensorInfo();
    fetchQueriedData();
  }, []);

  const fetchQueriedData = () => {
    axios
      .get(`http://localhost:5051/devices/${deviceId}/data/${start}/${end}`)
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error("Error fetching sensor data:", error);
      });
    return;
  };

  async function fetchSensorInfo() {
    const firstRequest = await axios
      .get(`http://localhost:5051/get_node_overview/${deviceId}`)
      .then((res) => {
        const sensors = res.data[0].info_types;
        setSensors(sensors);
      })
      .catch((error) => {
        console.error("Error fetching sensor list:", error);
      });

    const secondRequest = await axios
      .get("http://localhost:5051/get_data_metainfo")
      .then((res) => {
        // change later
        const sensors = tempSensorVals;
        const ret = {};
        const data = res.data;
        for (let i = 0; i < data.length; i++) {
          const sensor = data[i].sensor;
          delete data[i].sensor;
          if (sensors.includes(sensor)) {
            ret[sensor] = { ...data[i], series: null, show: true };
          } else {
            ret[sensor] = { ...data[i], series: null, show: false };
          }
        }
        setMetainfo(ret);
      })
      .catch((error) => {
        console.error(
          "Error fetching channel information from metainfo table:",
          error
        );
      });
  }

  return (
    <>
      {/* {tempSensorVals.map((sensor, i) => (
        <div>{sensor}</div>
      ))} */}
      {/* <Graph /> */}
      <GraphTest />
      {data && metainfo && tempSensorVals && (
        <Graph data={data} sensors={tempSensorVals} dataMetainfo={metainfo} />
      )}
      {/* <div>
      {cmd.graphs.map((graph, i) => (
        <div key={i}>
          {graph === "Line" ? (
              {sensors.map((sensor, i) => (
                <div key={i}>
                  <PlotLine
                    graph={graph}
                    sensor={sensor}
                    cmd={cmd}
                    toggle={toggle}
                  />
                  <Divider />
                </div>
              ))}
          ) : null}
          {graph === "Graph 1" ? <div>This is Graph 1</div> : null}
          {graph === "Graph 2" ? <div>This is not Graph 2</div> : null}
        </div>
      ))}
    </div> */}
    </>
  );
}

export default DeviceDashboard;
