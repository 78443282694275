import Dashboard from "./components/Dashboard";
import Alarms from "./components/Alarms";
import Devices from "./components/Devices";
import DeviceLayout from "./components/DeviceLayout"
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import DevicesIcon from "@mui/icons-material/Devices";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon />,
    component: <Dashboard/>,
    layout: "/admin",
  },
  {
    path: "/alarms",
    name: "Alarms",
    icon: <AccessAlarmsIcon />,
    component: <Alarms/>,
    layout: "/admin",
  },
  {
    path: "/devices",
    name: "Devices",
    icon: <DevicesIcon />,
    component: <Devices/>,
    layout: "/admin",
  },
  {
    path: "/devices/:deviceId", 
    name: "",
    component: <DeviceLayout/>,
    layout: "/admin",
  },
];

export default routes;
