import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

function Devices() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("http://localhost:5051/get_node_overview")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const columns = [
    { field: "node_id", headerName: "node_id", flex: 1 },
    { field: "ip", headerName: "ip", flex: 1 },
    { field: "topic", headerName: "topic", flex: 1 },
    { field: "time", headerName: "time", flex: 1 },
    { field: "state", headerName: "state", flex: 1 },
    { field: "sending_rate", headerName: "sending_rate", flex: 1 },
    { field: "info_types", headerName: "info_types", flex: 1 },
  ];

  const [sortModel, setSortModel] = useState([
    {
      field: "time",
      sort: "desc",
    },
  ]);

  const getRowId = (row) => row.node_id;

  const handleRowClick = (params) => {
    const deviceId = params.row.node_id;
    navigate(`/admin/devices/${deviceId}`);
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={data}
        getRowId={getRowId}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        selectionOnClick
        autoHeight
        checkboxSelection={false}
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter
        disableColumnReorder
        disableMultipleColumnsSorting
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)} // Update the sortModel on change
        loading={data.length === 0}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default Devices;
