import React from 'react';
import DeviceLayout from './components/DeviceLayout';
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import AdminLayout from "./components/Admin";
import Devices from "./components/Devices"
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <AdminLayout/>
  );
  }

export default App;
