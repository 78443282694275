import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Icon } from "@mui/material";
import "../css/Sidebar.css";

function Sidebar({ color, routes }) {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="sidebar" data-color={color}>
      <Nav>
        {routes.map((prop, key) => {
          if (!prop.redirect)
            return (
              <li
                className={
                  prop.upgrade
                    ? "active active-pro"
                    : prop.layout + prop.path === window.location.pathname
                    ? "active"
                    : ""
                }
                key={key}
                onClick={() => handleNavigate(prop.layout + prop.path)}
              >
                <NavLink to={prop.layout + prop.path} className="nav-link">
                  <Icon>{prop.icon}</Icon>
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
          return null;
        })}
      </Nav>
    </div>
  );
}

export default Sidebar;
